<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Enviar TED</h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="card">
        <div class="card-body" v-if="!docsOk">
          <div class="row">
            <div class="col-md-12 text-center">
              <ul class="list-unstyled my-4">
                <li>
                  <div class="d-flex align-items-center">
                    <div>
                      <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                        <i class="fas fa-arrow-right"></i>
                      </div>
                    </div>
                    <div>
                      <span class="pl-2 text-md">Antes de enviar um TED, é necessário verificar a conta, completando o seu cadastro.</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-md-12 text-center">
              <router-link to="/pages/user" class="btn btn-primary">
                <span class="btn-label"><i class="fas fa-tasks"></i> COMPLETAR CADASTRO</span>
              </router-link>
            </div>
          </div>
        </div>
        <div class="card-body" v-else>
          <div class="row">
            <div class="col-md-6" v-if="!showHistory">
              <div class="row">
                <div class="col-md-12">
                  <base-input label="Valor"
                              placeholder="0,00"
                              type="text"
                              name="Valor"
                              :rules="{max:14}"
                              v-money="money"
                              v-model="transfer.value">
                    <span slot="prepend">R$</span>
                  </base-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <label class="control-label float-right" style="margin-bottom: 0 !important;">
                    <a href="#" @click="loadHistory">
                      <i slot="label" class="fas fa-list"></i> Últimos Destinos</a>
                  </label>
                  <base-input label="Banco de Destino">
                    <autocomplete ref="bankAc"
                                  placeholder="Selecionar"
                                  :source="banksCodes"
                                  input-class="form-control"
                                  results-value="code"
                                  :results-display="displayBankName"
                                  @selected="selectBank">
                    </autocomplete>
                  </base-input>
                </div>
              </div>
              <div class="row" style="margin-top: 10px;">
                <div class="col-md-4">
                  <base-input label="Tipo de Conta Destino">
                    <el-select size="large" placeholder="Selecionar" style="width: 100%"
                               v-model="transfer.destinationAccountType">
                      <el-option class="select-success"
                                 value="CC"
                                 label="Corrente"/>
                      <el-option class="select-info"
                                 value="PP"
                                 label="Poupança"/>
                    </el-select>
                  </base-input>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-4">
                  <base-input
                    type="text"
                    label="Agência Destino"
                    name="Agência"
                    placeholder="Agência Destino"
                    :rules="{required: true}"
                    v-model="transfer.destinationAgency">
                  </base-input>
                </div>
                <div class="col-md-6">
                  <base-input
                    type="text"
                    label="Conta Destino"
                    name="Conta Destino"
                    placeholder="Conta Destino"
                    :rules="{required: true}"
                    v-model="transfer.destinationAccountNumber">
                  </base-input>
                </div>
                <div class="col-md-2">
                  <base-input
                    type="text"
                    label="Dígito"
                    name="Dígito"
                    placeholder="Dígito"
                    :rules="{required: true}"
                    v-model="transfer.destinationAccountNumberDv">
                  </base-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <base-input
                    type="text"
                    label="Nome Destino"
                    name="Nome Destino"
                    placeholder="Nome Destino"
                    :rules="{required: true, max: 30}"
                    v-model="transfer.destinationName">
                  </base-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <base-input
                    type="text"
                    label="CPF/CNPJ Destino"
                    name="CPF/CNPJ Destino"
                    placeholder="CPF/CNPJ Destino"
                    :rules="{required: true, cpfCnpjValid: true}"
                    v-mask="onlyDigits(notNull(this.transfer.destinationSocialNumber,'')).length <= 11 ? ['###.###.###-##'] : ['##.###.###/####-##']"
                    v-model="transfer.destinationSocialNumber"
                  >
                  </base-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12" style="text-align: center">
                  <div class="col-sm-12 text-center">
                    <label class="control-label">Descrição</label>
                    <input v-model="transfer.description" class="form-control" maxlength="20"
                           style="max-width: 50%; margin: 0 auto"/>
                  </div>
                </div>
              </div>
              <br/>
              <div class="row text-center">
                <div class="col-md-12 mb-3">
                  <button type="button" class="btn btn-primary" v-bind:disabled="sendingTed"
                          @click="doTransaction">
                    <span class="btn-label"><i class="fa fa-arrow-up"></i></span> Transferir
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-6" v-if="!showHistory">
              <card
                header-classes="bg-transparent"
                body-classes="px-lg-7"
                class="card-pricing border-0 text-center mb-4">

                <!--Header-->
                <h4 slot="header" class="text-uppercase ls-1 text-primary py-3 mb-0">Envie valores para outros
                  bancos!</h4>

                <!--Body-->
                <div class="display-2">R$ {{serviceFee}}</div>
                <span class=" text-muted">por transferência</span>
                <ul class="list-unstyled my-4">
                  <li>
                    <div class="d-flex text-left">
                      <div>
                        <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                          <i class="fas fa-arrow-right"></i>
                        </div>
                      </div>
                      <div>
                        <span class="pl-2 text-sm">Envie qualquer valor à outro banco.</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex text-left">
                      <div>
                        <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                          <i class="fas fa-arrow-right"></i>
                        </div>
                      </div>
                      <div>
                        <span class="pl-2 text-sm">Confira atentamente os dados bancários de destino.</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex text-left">
                      <div>
                        <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                          <i class="fas fa-arrow-right"></i>
                        </div>
                      </div>
                      <div>
                        <span class="pl-2 text-sm">O horário permitido para esta operação é de segunda à sexta, de <b>08:00</b> à <b>16:30</b>.</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex text-left">
                      <div>
                        <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                          <i class="fas fa-arrow-right"></i>
                        </div>
                      </div>
                      <div>
                        <span class="pl-2 text-sm">A transação pode levar de poucos minutos até um dia útil para ser concluída.</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </card>
            </div>
            <div class="col-md-12" v-if="showHistory">
              <h4 slot="header" class="card-title">
                Selecione um Destino
              </h4>
              <div class="row clickable-rows">
                <div class="col-md-12">
                  <div class="col-sm-12 mt-2">
                    <el-table class="table-striped"
                              :data="tableData"
                              @row-click="onBankReuseSelected"
                              style="width: 100%; cursor: pointer"
                              empty-text="Nenhuma transação encontrada no período informado.">
                      <el-table-column :min-width="130" label="Nome">
                        <template slot-scope="props">
                    <span>
                      <span><b>{{ props.row.destinationName }}</b></span>
                    </span>
                        </template>
                      </el-table-column>
                      <el-table-column :min-width="100" label="Banco">
                        <template slot-scope="props">
                    <span>
                      <span><b>{{ getBank(props.row.destinationBankCode).name }}</b></span>
                    </span>
                        </template>
                      </el-table-column>
                      <el-table-column :min-width="110" label="Agência/Conta">
                        <template slot-scope="props">
                    <span>
                      <span><b>{{ props.row.destinationAgency }}/{{ props.row.destinationAccountNumber }}-{{ props.row.destinationAccountNumberDv }}</b></span>
                    </span>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                  <div class="float-right mt-3 mb-2">
                    <base-pagination class="pull-right"
                                     v-model="pagination.currentPage"
                                     :per-page="pagination.perPage"
                                     :total="pagination.totalNumber"
                                     v-on:input="this.loadData">
                    </base-pagination>
                  </div>
                </div>
              </div>
              <div class="row text-center">
                <div class="col-md-12">
                  <button type="info" class="btn btn-primary" @click="showHistory = false">
                    <i slot="label" class="fas fa-undo"></i> Voltar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {Option, Select, Table, TableColumn} from 'element-ui';
  import {BasePagination} from '@/components';
  import {isNullOrEmpty, moneyToFloat, notNull, toMoney, onlyDigits} from "@/util/core.utils"
  import {failWs, getWs, postWs} from '@/ws.service'
  import banksCodes from '@/util/banks-codes'
  import Autocomplete from 'vuejs-auto-complete'
  import DzsTedTranferDetails from "@/components/UIComponents/Dzs/Transfer/DzsTevTransferDetails"

  export default {
    components: {
      BasePagination,
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      DzsTedTranferDetails,
      Autocomplete,
    },
    data() {
      return {
        docsOk: false,
        sendingTed: false,
        value: '0,00',
        money: {
          decimal: ',',
          thousands: '.',
          prefix: '',
          suffix: '',
          precision: 2,
          masked: false /* doesn't work with directive */
        },
        serviceFee: '3,90',
        validateIntervalLoop: null,
        accountBalance: '0,00',
        transfer: {
          destination: '',
          destinationName: '',
          destinationSocialNumber: '',
          value: '',
          description: '',
          destinationBankCode: '',
          destinationBankName: '',
          destinationAccountType: '',
          destinationAgency: '',
          destinationAccountNumber: '',
          destinationAccountNumberDv: ''
        },
        showHistory: false,
        tableData: [],
        pagination: {
          perPage: 5,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
        propsToSearch: ['destinationName', 'destinationSocialNumber', 'destinationAgency', 'destinationAccountNumber'],
        banksCodes: banksCodes,
      }
    },
    created() {
      this.docsOk = localStorage.getItem("docsOk") == 'true'
      if (!this.docsOk) {
        return
      }
      this.accountBalance = notNull(localStorage.getItem('accountBalance'), '0,00')
      this.loadFee();
    },
    methods: {
      toMoney,
      moneyToFloat,
      isNullOrEmpty,
      onlyDigits,
      notNull,
      doTransaction() {
        this.sendingTed = true

        let valueAndFee = (moneyToFloat(this.transfer.value) + moneyToFloat(this.serviceFee))
        if (valueAndFee > moneyToFloat(this.accountBalance)) {
          this.$swal({
            title: 'Aviso!',
            text: 'Você não possui saldo suficiente.',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-warning btn-fill'
            },
            icon: 'warning'
          })
          this.sendingTed = false
          return
        } else if (isNullOrEmpty(this.transfer.destinationBankCode)) {
          this.$swal({
            title: 'Aviso!',
            text: 'O campo Banco Destino é obrigatório.',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-warning btn-fill'
            },
            timer: 5000,
            icon: 'warning'
          })
          this.sendingTed = false
          return
        } else if (isNullOrEmpty(this.transfer.destinationAccountType)) {
          this.$swal({
            title: 'Aviso!',
            text: 'O campo Tipo de Conta Destino é obrigatório.',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-warning btn-fill'
            },
            timer: 5000,
            icon: 'warning'
          })
          this.sendingTed = false
          return
        }
        if (moneyToFloat(this.transfer.value) <= 0) {
          this.$swal({
            title: 'Aviso!',
            text: 'O valor tem que ser maior que ZERO!',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-warning btn-fill'
            },
            icon: 'warning'
          })
          this.sendingTed = false
          return
        }

        this.$swal({
          title: 'Confirma a transação ?',
          html: "NOME DESTINO: <b>" + this.transfer.destinationName.toUpperCase() + "</b> <br/>" +
            "DOC. DESTINO: <b>" + this.transfer.destinationSocialNumber + "</b> <br/>" +
            "VALOR: <b>R$ " + this.transfer.value + "</b> <br/>",
          icon: 'info',
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: 'SIM',
          cancelButtonText: 'cancelar',
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill'
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.callTedWs()
          } else {
            this.sendingTed = false
          }
        })
      },
      callTedWs() {
        this.transfer.description = this.transfer.description.toUpperCase()
        this.transfer.destinationName = this.transfer.destinationName.toUpperCase()
        postWs("/transaction/ted",
          true, null,
          {
            value: moneyToFloat(this.transfer.value),
            destinationBankCode: this.transfer.destinationBankCode,
            destinationAccountType: this.transfer.destinationAccountType,
            destinationAgency: this.transfer.destinationAgency,
            destinationAccountNumber: this.transfer.destinationAccountNumber,
            destinationAccountNumberDv: this.transfer.destinationAccountNumberDv,
            destinationName: this.transfer.destinationName,
            destinationSocialNumber: this.transfer.destinationSocialNumber,
            description: this.transfer.description
          },
          (response) => {
            this.sendingTed = false
            if (response.data.response === 'OK') {
              this.success()
              return
            }
            let text = 'Falha ao transferir! Tente mais tarde.'
            this.$swal({
              title: 'Aviso!',
              text: text,
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-warning btn-fill'
              },
              icon: 'warning'
            })
          }, (error) => {
            this.sendingTed = false
            if (error == null) {
              return
            }

            let text = null
            if (error.response.data.response == 'NO_MOBILE_DEVICE_REGISTERED') {
              text = 'Nenhum dispositivo de verificação cadastrado! Para prosseguir, cadastre clicando no menu \'CADASTRAR DISPOSITIVO\'.'
            } else if (error.response.data.response == 'NO_FUNDS') {
              text = 'Você não possui saldo suficiente! Altere o valor e tente novamente.'
            } else if (error.response.data.response == 'ACCOUNT_DOCUMENTS_NOT_VALIDATED') {
              text = 'Conta ainda não verificada! Para prosseguir, favor preencher os dados e enviar a documentação clicando no menu \'VERIFICAR CONTA\'.'
            } else if (error.response.data.response == 'SOURCE_USER_HAVE_NO_ENABLED_ACCOUNT' || error.response.data.response == 'BLOCKED_OUT_TRANSACTIONS' ||
              error.response.data.response == 'BLOCKED') {
              text = 'Sua conta foi bloqueada! Entre em contato com nosso suporte.'
            } else if (error.response.data.response == 'OPERATION_EXCEEDS_LIMIT') {
              text = 'Esta transação excede os limites para sua conta. Entre em contato com nosso suporte para solicitar um aumento no seu limite.'
            } else if (error.response.data.response == 'SOURCE_USER_INACTIVE') {
              text = 'Sua conta está inativa! Entre em contato com nosso suporte.'
            } else if (error.response.data.response == 'OPERATION_EXCEEDS_TIME_LIMIT') {
              text = 'As solicitações de TED podem ser realizadas de segunda a sexta das 08h à 16h30, tente novamente no horário permitido.'
            } else if (error.response.status == 412) {
              return;
            }
            if (!isNullOrEmpty(text)) {
              this.$swal({
                title: 'Aviso!',
                text: text,
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-warning btn-fill'
                },
                icon: 'warning'
              })
            } else {
              this.$swal({
                title: 'Serviço temporariamente indisponível!',
                text: 'Tente novamente mais tarde.',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-danger btn-fill'
                },
                timer: 5000,
                icon: 'error'
              })
            }
          },
          true)
      },
      transferSuccess(response) {
        if (response.data.status == 'USED') {
          this.success()
        }
      },
      success() {
        clearInterval(this.validateIntervalLoop)
        this.accountBalance = toMoney(moneyToFloat(this.accountBalance) - moneyToFloat(this.transfer.value) - moneyToFloat(this.serviceFee))
        localStorage.setItem('accountBalance', this.accountBalance)

        this.transfer.value = ''
        this.transfer.destination = ''
        this.transfer.destinationName = ''
        this.transfer.destinationSocialNumber = ''
        this.transfer.description = ''

        this.transfer.destinationBankName = ''
        this.transfer.destinationBankCode = ''
        this.transfer.destinationAccountType = ''
        this.transfer.destinationAgency = ''
        this.transfer.destinationAccountNumber = ''
        this.transfer.destinationAccountNumberDv = ''

        this.$swal({
          title: 'Sucesso!',
          text: 'Valor transferido com sucesso!',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-success btn-fill'
          },
          timer: 5000,
          icon: 'success'
        })
      },
      loadHistory() {
        this.showHistory = true
        postWs("/transaction/ted-condensed-history",
          true, null,
          {
            pageNumber: (this.pagination.currentPage - 1),
            pageSize: this.pagination.perPage
          },
          this.successLoadData
          , () => {
            this.tableData = []
          })
      },
      onBankReuseSelected(destination) {
        this.showHistory = false
        setTimeout(function (vueComponent, destination, progress) {
          vueComponent.selectDestination(destination)
        }, 500, this, destination)
      },
      selectDestination(destination) {
        this.transfer.destination = destination.destination
        this.transfer.destinationName = destination.destinationName
        this.transfer.destinationSocialNumber = destination.destinationSocialNumber
        this.transfer.destinationBankCode = destination.destinationBankCode
        this.transfer.destinationBankName = this.getBank(destination.destinationBankCode).name
        this.transfer.destinationAccountType = destination.destinationAccountType
        this.transfer.destinationAgency = destination.destinationAgency
        this.transfer.destinationAccountNumber = destination.destinationAccountNumber
        this.transfer.destinationAccountNumberDv = destination.destinationAccountNumberDv
        this.$refs.bankAc.display = this.displayBankName(this.getBank(destination.destinationBankCode))
        this.$refs.bankAc.value = destination.destinationBankCode
      },
      getBank(code) {
        for (let bank in this.banksCodes) {
          if (this.banksCodes[bank].code == code) {
            return this.banksCodes[bank]
          }
        }
        return null
      },
      selectBank(bank) {
        this.transfer.destinationBankCode = bank.selectedObject.code
        this.transfer.destinationBankName = bank.selectedObject.name
      },
      displayBankName(bank) {
        return bank.code + ' - ' + bank.name
      },
      loadData() {
        postWs("/transaction/ted-condensed-history",
          true, null,
          {
            pageNumber: (this.pagination.currentPage - 1),
            pageSize: this.pagination.perPage
          }, this.successLoadData, failWs)
      },
      successLoadData(response) {
        this.tableData = response.data.list
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      loadFee() {
        getWs("/fee/list-user-fees",
          true, null, this.successLoadDataFee,
          failWs)
      },
      successLoadDataFee(response) {
        for (let fee of response.data.list) {
          if (fee.type === 'TED') {
            var valueFee = toMoney(fee.value);
            this.serviceFee = valueFee;
          }
        }
      }
    }
  }

</script>
<style>
  .no-border-card .card-footer {
    border-top: 0;
  }
</style>
